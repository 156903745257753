import { reactive } from 'vue'
const dayjs = require('dayjs');

var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

export const store = reactive({
    authenticated: false,
    server: null,
    router: null,
    token: null,
    team: null,
    teamID: null,
    teamUser: null,
    monate: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    wochentage: ['Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag', 'Sonntag'],
    teams: null,

    // COLORS
    colors: {
        lightblue: '#b7e4ff', // u.a. Hintergrundfarbe
        primaryblue: '#7ab8e5', // #7ab8e5, rgba(122,184,229)
        darkblue: '#175986',
    },

    logout() {
        this.server.auth.logout();
        this.authenticated = false;
        this.router.push({ name: 'App' })
    },
    getToday() {
        let b = new Date();
        let day = this.doNull(b.getDate());
        let month = this.doNull(b.getMonth()+1);
        let year = b.getFullYear();
        //window.console.log(year +'-'+month+'-'+day);
        return year +'-'+month+'-'+day;
    },
    getClock(timestamp = null) {
        let a = new Date(timestamp);
        let hours = a.getHours();
        let minutes = a.getMinutes();
        if(hours < 10){hours = '0'+hours;}
        if(minutes < 10){minutes = '0'+minutes;}
        return hours+':'+minutes+' Uhr';
    },
    getDay( timestamp = null ) {
        let curr = new Date(timestamp);
        let dayIndex = (curr.getDay() + 6) % 7;
        return dayIndex
    },
    getDate(timestamp = null) {
        let b = new Date(timestamp);
        let day = b.getDate();
        let month = b.toLocaleString('de-DE', { month: 'long' });
        let year = b.getFullYear();
        return day+'. '+month+' '+year;
    },
    getDateStamp(timestamp = null){

        timestamp = new Date(timestamp);

        let date = timestamp.getFullYear() + "-" + this.doNull(timestamp.getMonth()+1) + "-" + this.doNull(timestamp.getDate());
        return date;
    },
    doNull(wert){
        if (wert < 10) return "0" + parseInt(wert);
        else return parseInt(wert);
    },
    calcDauer(dauerMs, operator = false){
        var dauerOriginal = dauerMs;
        if ( dauerMs < 0 ){
            // Wenn negativ, dann machs für die Berechnung positiv.
            dauerMs = Math.abs(dauerMs)
        }
        let dauer = this.doNull((dauerMs/1000/60/60)%24) + ":" + this.doNull((dauerMs/1000/60)%60) + ":" + this.doNull((dauerMs/1000)%60);

        if ( dauerOriginal < 0 && operator ){
            // Wenns negativ war, dann gibs jetzt auch wieder negativ aus.
            return '- '+dauer;
        }
        else if ( dauerOriginal > 0 && operator ) {
            return '+ '+dauer;
        }
        return dauer;
    },
    async getTeams(){
        const teams = await this.server.items('team').readByQuery({
            filter: {
                users: {
                    directus_users_id: {
                        _in: '$CURRENT_USER',
                    }
                }

            },
            sort: ['id']
        });
        this.teams = teams.data;

        window.console.log('Store-Function getTeams', teams.data)

        // Nur wenn schon Teams gefunden wurde und noch kein "self-selected" Team gesetzt wurde
        // Dann setze das erste gefundene Team als Standard-Team.
        if ( this.teams && this.team == null ){
            this.team = this.teams[0];
            this.teamID = this.teams[0].id;
            await this.getTeam(null, this.teamID )
        }
        return teams.data;
    },
    // Hole das Team-Model über den TeamUser, oder hole den TeamUser und das Team-Model über die TeamID
    async getTeam(team_userID = null, teamID = null){

        if (team_userID){
            // Holt TeamUser-Model, setzt this.teamUser
            const team_user = await this.server.items('team_directus_users').readByQuery({
                filter: {
                    id: {
                        _eq: team_userID,
                    }

                },
                limit: 1,
                sort: ['id']
            });

            this.teamUser = team_user.data[0];

            // Holt Team-Model, setzt this.teamID und this.team
            const team = await this.server.items('team').readByQuery({
                filter: {
                    id: {
                        _eq: this.teamUser.team_id,
                    }
                },
                limit: 1,
                sort: ['id']
            });

            this.teamID = team.data[0].id;
            this.team = team.data[0];
        }
        else if ( teamID ){


            if ( !this.teamID || this.teamID != teamID ){
                const team = await this.server.items('team').readByQuery({
                    filter: {
                        id: {
                            _eq: teamID,
                        }

                    },
                    limit: 1,
                    sort: ['id']
                });

                this.teamID = team.data[0].id;
                this.team = team.data[0];
            }

            const team_user = await this.server.items('team_directus_users').readByQuery({
                filter: {
                    team_id: {
                        _eq: this.teamID,
                    },
                    directus_users_id: {
                        _in: '$CURRENT_USER',
                    }

                },
                sort: ['id'],
                limit: 1,
            });

            this.teamUser = team_user.data[0];
        }

        window.console.log('Store-Function getTeam', this.team, this.teamID, this.teamUser )
        return this.team;
    },
    async calcDayTime(endStamp, type){

        let heute = new Date();
        heute = this.getDateStamp(heute);

        let typ;
        switch ( type ) {
            case "leave":
                typ = "arrive";
                break;
            case "break":
                typ = "break";
                break;
            default:
                typ = "arrive";
                break;
        }

        const stempel = await this.server.items('stamps').readByQuery({
            filter: {
                team_user: {
                    _eq: this.teamUser.id,
                },
                type: {
                    _eq: typ
                },
                time: {
                    _between: [heute+" 00:00:00", heute+" 23:59:59"],
                }
            },
            limit: 1,
            sort: ['-id'],
        });

        window.console.log('Store-Function getStempel', stempel, heute+" 00:00:00", heute+" 23:59:59")

        let startTime = new Date(stempel.data[0].time);
        let startUnix = startTime.getTime();

        let endTime = new Date(endStamp.time);
        let endUnix = endTime.getTime();

        //window.console.log('Store-Function calcDayTime, arrive-stamp', stempel)
        //window.console.log('Store-Function calcDayTime, startUnix', startUnix)
        //window.console.log('Store-Function calcDayTime, endUnix', endUnix)

        let dauerMs = endUnix - startUnix;
        //let dauer = this.calcDauer(dauerMs);

        window.console.log('Store-Function calcDayTime, stempel, dauer', stempel, dauerMs)

        window.console.log('Store-Function calcDayTime, heuteTimestamp', heute)

        const dayTime = await this.server.items('day_times').readByQuery({
            filter: {
                team_user: {
                    _eq: this.teamUser.id,
                },
                date: {
                    _eq: heute,
                }
            },
            limit: 1,
            sort: ['-id'],
        });

        if ( dayTime.data.length > 0 ){
            window.console.log ('Hat schon ne DayTime -> addieren.', dayTime)

            if ( type == "leave" ){
                let existingDauer = dayTime.data[0].duration
                let newDauer = existingDauer + dauerMs;

                const update = await this.server.items('day_times').updateOne(dayTime.data[0].id, {
                    duration: newDauer,
                });
                window.console.log ('newDauerDuration', newDauer, update);
            }
            else if ( type == "break" ){
                let existingDauer = dayTime.data[0].break
                let newDauer = existingDauer + dauerMs;

                const update = await this.server.items('day_times').updateOne(dayTime.data[0].id, {
                    break: newDauer,
                });
                window.console.log ('newDauerBreak', newDauer, update);
            }
        }
        else {
            window.console.log ('Hat keine DayTime -> create.', dayTime)

            // Hier braucht es keinen IF, weil man den Tag eigentlich nicht mit einer Pause beginnen kann.
            const create = await this.server.items('day_times').createOne({
                date: heute,
                team_user: this.teamUser.id,
                duration: dauerMs,
            });
            window.console.log ('createDayTime', create);
        }

    },
    test (){
        let dauer = dayjs.duration({
            seconds: 2,
            minutes: 2,
            hours: 2,
            days: 2,
            weeks: 2,
            months: 2,
            years: 2
        });


        window.console.log('test', dauer);
    }
})
<template>
    <main>
        <div id="inner">
            <div id="menu" v-if="store.authenticated">
                <div id="sticky">
                    <v-hover v-slot="{ isHovering, props }">
                        <!-- STARTSEITE -->
                        <v-btn
                             variant="text"
                             icon="fa:fas fa-house-chimney"
                             :color="isHovering ? store.colors.darkblue : 'white'"
                             v-bind="props"
                             :ripple="false"
                             @click="this.$router.push({ name: 'Stempeluhr' });"
                        ></v-btn>
                    </v-hover>
                    <v-hover v-slot="{ isHovering, props }">
                        <!-- PROJEKTE / AUFTRÄGE -->
                        <v-btn
                            variant="text"
                            icon="fa:fas fa-file-invoice"
                            :color="isHovering ? store.colors.darkblue : store.colors.lightblue"
                            v-bind="props"
                            :ripple="false"
                        ></v-btn>
                    </v-hover>
                    <v-hover v-slot="{ isHovering, props }">
                        <!-- KUNDEN -->
                        <v-btn
                            variant="text"
                            icon="fa:fas fa-address-card"
                            :color="isHovering ? store.colors.darkblue : store.colors.lightblue"
                            v-bind="props"
                            :ripple="false"
                        ></v-btn>
                    </v-hover>
                    <v-hover v-slot="{ isHovering, props }">
                        <!-- STATISTIKEN -->
                        <v-btn
                            variant="text"
                            icon="fa:fas fa-chart-simple"
                            :color="isHovering ? store.colors.darkblue : store.colors.lightblue"
                            v-bind="props"
                            :ripple="false"
                        ></v-btn>
                    </v-hover>
                    <v-hover v-slot="{ isHovering, props }">
                        <!-- MITARBEITER -->
                        <v-btn
                            variant="text"
                            icon="fa:fas fa-user-group"
                            :color="isHovering ? store.colors.darkblue : 'white'"
                            v-bind="props"
                            :ripple="false"
                            @click="this.$router.push({ name: 'Team' });"
                        ></v-btn>
                    </v-hover>
                    <hr />
                    <v-hover v-slot="{ isHovering, props }">
                        <v-btn
                            variant="text"
                            icon="fa:fas fa-user"
                            :color="isHovering ? store.colors.darkblue : 'white'"
                            v-bind="props"
                            :ripple="false"
                            @click="this.$router.push({ name: 'User' });"
                        ></v-btn>
                    </v-hover>
                    <v-hover v-slot="{ isHovering, props }">
                        <v-btn
                            variant="text"
                            icon="fa:fas fa-clock"
                            :color="isHovering ? store.colors.darkblue : 'white'"
                            v-bind="props"
                            :ripple="false"
                            @click="this.$router.push({ name: 'UserZeiten' });"
                        ></v-btn>
                    </v-hover>
                    <hr />
                    <v-hover v-slot="{ isHovering, props }">
                        <v-btn
                            variant="text"
                            icon="fa:fas fa-right-from-bracket"
                            :color="isHovering ? store.colors.darkblue : 'white'"
                            v-bind="props"
                            :ripple="false"
                            @click="this.store.logout();"
                        ></v-btn>
                    </v-hover>

                </div>
            </div>
            <div id="logo">
                <img src="@/assets/logo.png" />
            </div>

            <v-row id="login" v-if="!store.authenticated">
                <v-col cols="12">
                    <h2>Einloggen</h2>
                </v-col>
                <v-col cols="12">
                    <v-form v-model="valid">
                        <v-text-field
                            v-model="loginData.email"
                            :rules="emailRules"
                            label="E-Mail"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="loginData.password"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show ? 'text' : 'password'"
                            name="Passwort"
                            label="Passwort"
                            counter
                            @click:append="show = !show"
                        ></v-text-field>
                        <v-btn @click="login()" :color="store.colors.lightblue" variant="flat">
                            Einloggen
                        </v-btn>
                    </v-form>
                    <!--
                    <el-form :model="loginData" id="loginForm">
                        <el-form-item>
                            <el-input v-model="loginData.email">
                                <template #prepend><label>E-Mail</label></template>
                            </el-input>
                        </el-form-item>
                        <el-form-item >
                            <el-input v-model="loginData.password" type="password" autocomplete="off">
                                <template #prepend>Passwort</template>
                            </el-input>
                        </el-form-item>
                        <el-button type="primary" @click="login()">
                            Login!
                        </el-button>
                    </el-form>
                    -->
                </v-col>

            </v-row>
            <router-view v-if="store.authenticated"></router-view>
        </div>
    </main>


</template>

<script>
import { Directus } from '@directus/sdk';
import { store } from '@/store.js'
//import { ElNotification } from 'element-plus'

export default {
    name: 'App',
    components: {

    },
    computed: {

    },
    data() {
        return {
            //authenticated: false,
            //server: null,
            store,
            loginData: { email: '', password: '' },
            valid: false,
            emailRules: [
                value => {
                    if (value) return true

                    return 'Bitte geben Sie die hinterlegte E-Mail-Adresse an.'
                },
                value => {
                    if (/.+@.+\..+/.test(value)) return true

                    return 'Es muss eine gültige E-Mail-Adresse eingegeben werden.'
                },
            ],
            show: false,
            rules: {
                required: value => !!value || 'Pflichtfeld',
                min: v => v.length >= 8 || 'Das Passwort muss mindestens 8 Zeichen lang sein.',
                emailMatch: () => (`E-Mail oder Passwort ist falsch.`),
            },
        }
    },
    async created() {
        this.store.server = new Directus('https://timetasksrv.suedheide.media');
        this.store.router = this.$router;

        if (this.store.server && this.store.authenticated == false) {
            await this.store.server.auth
                .refresh()
                .then(( response ) => {
                    this.store.authenticated = true;
                    window.console.log('Eingeloggt.', response)
                    this.store.token = this.store.server.auth.token;
                })
                .catch(() => {
                    window.console.log('Nicht eingeloggt.');
                });
            if (this.store.authenticated && this.store.token ) {
                this.$router.push({ name: 'Stempeluhr' })
            }
        }
    },
    async mounted() {


    },
    methods: {
        async login() {
            //const me = this;
            const email = this.loginData.email;
            const password = this.loginData.password;
            //
            await this.store.server.auth
                .login({ email, password })
                .then(() => {
                    this.store.authenticated = true;
                    this.store.token = this.store.server.auth.token;
                    window.console.log('Login-Try', this.store.token);
                })
                .catch(() => {
                    window.alert('Invalid credentials');
                });
            if (this.store.authenticated && this.store.token ) {
                window.console.log('Push Stempeluhr');
                this.$router.push({ name: 'Stempeluhr' })
            }
        },
        async checkTeamAdmin() {
            await this.store.getTeams();
            // Ich brauch ne Abfrage, ob der User irgendwo Admin oder Personaler ist.
            // Nur dann den Button Teams im Sticky-Menu einblenden.
            // Im TeamHandler darf er dann auch nur diese Teams bearbeiten können.
        }
    }
}
</script>

<style>

/*
* Color-Picker für Farbabstufungen: https://imagecolorpicker.com/color-code/6ea6ce
 */

/* DunkelBlau: #7ab8e5, rgba(122,184,229) */
/* Hellblau rgba(183,228,255); */
/* Beere #a4287b rgba(164,40,123); */

:root {
    --el-border-color: rgba(183,228,255);
    --el-color-primary: rgba(122,184,229);
    --el-color-primary-light-3: rgba(110,166,206);
    --el-color-danger: rgba(164,40,123);
}

body {
    background-color: #b7e4ff;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin: 0;
}

h2 {
    text-transform: uppercase;
    font-weight: lighter;
    margin: 0;
    margin-bottom: 0.8rem;
}

hr {
    border: 0.5px solid rgba(183,228,255);
    width: 100%;
    margin: 2rem 0;
}

.el-select {
    --el-select-border-color-hover: rgba(122,184,229);
}

.el-input {
    --el-input-bg-color: #ecf5ff;
}

.el-button svg {
    margin-right: 0.5rem;
}

.el-button.solo svg {
    margin-right: 0rem;
}

#app {
    position: relative;
}

#inner {
    min-height: 100vh;
    height: 100%;
    width: 750px;
    margin: 0 auto;
    padding: 0rem 4rem;
    background-color: #fff;
    position: relative;
}

#menu {
    top: 3%;
    left: -50px;
    position: absolute;
    height: 100%;
}

#menu #sticky {
    display: flex;
    flex-direction: column;
    position: sticky;
    width: 50px;
    top: 0px;
    background-color: rgba(122,184,229);
    height: auto;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 1rem 10px;
    justify-content: center;
}

#menu #sticky hr {
    border: 1px solid white;
    width: 100%;
    margin: 0.5rem 0;
}

#menu #sticky svg {
    display: block;
    margin: 0.6rem;
    height: 2rem;
    justify-content: center;
    color: white;
}

#menu #sticky svg.inactive {
    color: lightblue;
}

#menu #sticky svg:hover, #menu #sticky svg:active, #menu #sticky svg:focus {
    color: rgba(164,40,123);
}

#logo {
    text-align: center;
    padding: 3rem 0;
}

#login h2 {
    text-align: center;
}

.big_button {
    text-transform: uppercase;
    width: 100%;
}

.form_button {
    float: right;
}

#loginForm .el-input-group__prepend {
    width:7%;
    text-align: left;
    justify-content: left;
}
</style>

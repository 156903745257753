<template>
    <h1>User Zeiten</h1>

    <el-row :gutter="20" id="zeiten" style="margin-bottom: 20px;">
        <el-col :span="8">
            <el-select v-model="teamChosen">
                <el-option
                    v-for="item in filterOptions.teams"
                    :key="item.id"
                    :label="item.Name"
                    :value="item.id"
                    default-first-option
                />
            </el-select>
        </el-col>
        <el-col :span="8">
            <el-select v-model="MonatChosen.Monat">
                <el-option
                    v-for="(item, index) in filterOptions.months"
                    :key="index"
                    :label="item"
                    :value="index"
                    default-first-option
                />
            </el-select>
        </el-col>
        <el-col :span="4">
            <el-select v-model="YearChosen">
                <el-option
                    v-for="(item, index) in filterOptions.years"
                    :key="index"
                    :label="item"
                    :value="index"
                    default-first-option
                />
            </el-select>
        </el-col>
        <el-col :span="4">
            <el-button type="primary" @click="getZeiten(this.teamChosen, this.MonatChosen.Monat, this.YearChosen)">Suchen</el-button>
        </el-col>
    </el-row>

    <el-table
        :data="zeiten"
        style="width: 100%"
    >
        <el-table-column type="expand">
            <template #default="props">
                <div m="4">
                    <el-table :data="props.row.stempelungen">
                        <el-table-column label="Uhrzeit" prop="uhrzeit" />
                        <el-table-column label="Vorgang" prop="vorgang" />
                        <el-table-column label="Dauer Arbeitszeit" prop="dauer" />
                        <el-table-column label="Dauer Pause" prop="dauerPause" />
                    </el-table>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="datum" label="Datum" width="180" />
        <el-table-column prop="wochentag" label="Tag" width="180" />
        <el-table-column prop="arbeitszeit" label="Arbeitszeit" />
        <el-table-column prop="pausen" label="Pausenzeit" />
        <el-table-column prop="plusminus" label="Arbeitssoll" />
    </el-table>
</template>

<script>
import {store} from "@/store";

export default {
    name: 'UserZeiten',
    data() {
        return {
            store,
            datum: null,
            wochentag: null,
            teamChosen: null,
            YearChosen: null,
            MonatChosen: {
                Monat: null,
                Monatsname: null,
                anzahlTage: null,
            },
            zeiten: [],
            filterOptions: {},

        }
    },
    async mounted() {
        if ( this.store.authenticated ) {

            // Date Heute, bzw. aktueller Monat und Jahr
            let a = new Date();
            //let day = a.getDate();
            let month = a.toLocaleString('de-DE', { month: 'long' });
            let year = a.getFullYear();

            // Chosen Ones
            this.YearChosen = year;
            this.MonatChosen.Monat = a.getMonth();
            this.MonatChosen.Monatsname = month;

            // FILTER-OPTIONS
            // Es kann gefiltert werden nach
            // TEAM /// MONAT /// JAHR ///

            // Hole die zugehörigen Teams zum User
            // HINWEIS: Ehemalige Teams wären vielleicht auch noch interessant?!?

            this.filterOptions.months = this.store.monate;

            let stop = year-10;
            this.filterOptions.years = [];

            while ( stop <= year ){
                this.filterOptions.years.push(stop);
                stop++;
            }
            this.filterOptions.years.reverse();

            /*
            const teams = await this.store.server.items('Team').readByQuery({
                filter: {
                    Users: {
                        directus_users_id: {
                            _contains: '$CURRENT_USER',
                        }
                    }

                },
                sort: ['id']
            });


            this.filterOptions.teams = teams.data;
            */
            if ( !this.store.teams ){
                await this.store.getTeams();
            }
            this.filterOptions.teams = this.store.teams;
            window.console.log('teams', this.filterOptions.teams)

            // Letztes ausgewähltes Team ausm Store als Vorbelegung holen.
            if ( this.store.team ){
                this.teamChosen = this.store.team;
            }
            else {
                this.teamChosen = this.store.teams[0];
            }
            await this.getZeiten(this.teamChosen, this.MonatChosen.Monat, this.YearChosen );

        }
    },
    methods: {
        async getZeiten (teamChosen, monatChosen, yearChosen){
            // Holt jetzigen Timestamp, speichert Datum in this.datum
            let a = new Date();
            let day = a.getDate();
            let month = a.toLocaleString('de-DE', { month: 'long' });
            let year = a.getFullYear();
            this.datum = day+'. '+month+' '+year;

            /* Braucht es glaub ich nicht mehr an dieser Stelle.
            // Holt aktuellen Wochentag, Monat(Key), Monatsname & Jahr
            this.wochentag = this.store.wochentage[a.getDay()];
            // TO-DOOOOO: Hier müsste später die Auswahl aus Selects eingefügt werden.
            this.YearChosen = a.getFullYear();
            this.MonatChosen.Monat = a.getMonth();
            this.MonatChosen.Monatsname = month;
            */

            // Hol mir mal die hinterlegten Soll-Arbeitszeiten
            // HINWEIS FÜR SPÄTER: Für Personaler ist nicht nur erfüllte Sollzeit relevant, sondern auch Pünktlichkeit.
            const sollzeiten = await this.store.server.items('Arbeitszeit').readByQuery({
                filter: {
                    teamID: {
                        _eq: teamChosen,
                    },
                    userID: {
                        _eq: '$CURRENT_USER',
                    }
                },
                sort: ['id'],
            });
            //window.console.log('Sollzeiten', sollzeiten);

            // Übergibt aktuelle Daten an getCalendar
            this.getCalendar(monatChosen, yearChosen);

            window.console.log(yearChosen+'-'+(monatChosen+1))

            var stempel = null;
            if ( teamChosen ) {
                stempel = await this.store.server.items('Stempelung').readByQuery({
                    filter: {
                        teamID: {
                            _eq: teamChosen,
                        },
                        user_created: {
                            _eq: '$CURRENT_USER',
                        },
                        date_created: {
                            _contains: yearChosen+'-'+(monatChosen+1),
                        }
                    },
                    sort: ['id'],
                });
            }
            // Eigentlich überflüssig, weil durch die Standard-Belegung im Select immer ein teamChosen gesetzt sein sollte?
            else {
                stempel = await this.store.server.items('Stempelung').readByQuery({
                    filter: {
                        teamID: {
                            _eq: this.store.team,
                        },
                        user_created: {
                            _eq: '$CURRENT_USER',
                        }
                    },
                    sort: ['id'],
                });
            }


            if ( stempel ){
                //window.console.log('Alle Stempel', stempel)

                for (const day of this.zeiten ){
                    day.stempelungen = [];

                    let startzeit = null;
                    let endzeit = null;
                    let startPause = null;
                    let endePause = null;

                    let arbeitszeit = null;
                    let pausen = null;

                    for ( const stemp of stempel.data ){

                        let stempDate = new Date(stemp.date_created);
                        let stempDay = stempDate.getDate();
                        if ( stempDay == day.daystamp ){

                            // SONDERFALL: Nachtschichtler beenden Ihre Schicht eventuell erst am nächsten Tag.
                            // Zeitrechnung muss also Tagübergreifend sein, wenn am Vortag nicht ausgestempelt wurde.
                            // Wie lösen?!?

                            let uhrzeit = this.store.getClock(stemp.date_created);

                            // Für die Ist-Arbeitszeit muss ich das erste Kommen und das letzte Gehen des Tages herausfiltern und subtrahieren
                            // Stimmt nicht. Ich muss das Kommen vom NÄCHSTEN Gehen subtrahieren. Kann ja auch mehrmals am Tag ein- und ausgestempelt werden.

                            if (stemp.Vorgang == 'kommen') {
                                startzeit = stempDate;
                                day.stempelungen.push({ 'uhrzeit': uhrzeit, 'vorgang': stemp.Vorgang });
                            }
                            else if ( stemp.Vorgang == 'gehen' ){
                                endzeit = stempDate;
                                let dauerMs = endzeit.getTime() - startzeit.getTime();
                                let dauer = this.store.calcDauer(dauerMs);

                                day.stempelungen.push({ 'uhrzeit': uhrzeit, 'vorgang': stemp.Vorgang, 'dauer': dauer, 'arbeitMs': dauerMs });
                                arbeitszeit = arbeitszeit+dauerMs;
                                endzeit = startzeit = 0;
                            }
                            else if ( stemp.Vorgang == 'pauseStart' ){
                                startPause = stempDate;
                                day.stempelungen.push({ 'uhrzeit': uhrzeit, 'vorgang': stemp.Vorgang });
                            }
                            else if ( stemp.Vorgang == 'pauseEnd' ){
                                // Hier muss die Dauer der Pause errechnet werden
                                endePause = stempDate;
                                let dauerMs = endePause.getTime() - startPause.getTime();
                                let dauer = this.store.calcDauer(dauerMs);

                                day.stempelungen.push({ 'uhrzeit': uhrzeit, 'vorgang': stemp.Vorgang, 'dauerPause': dauer, 'pauseMs': dauerMs  });
                                pausen = pausen+dauerMs;
                                endePause = startPause = 0;
                            }
                            else {
                                // Setze erstmal nothing
                            }
                        }
                    }

                    day.arbeitszeit = this.store.calcDauer(arbeitszeit);
                    day.pausen = this.store.calcDauer(pausen);

                    for ( const soll of sollzeiten.data ) {
                        if ( day.wochentag == soll.wochentag ){
                            // Ms der Soll-Arbeitszeit berechnen
                            // arbeitszeit (sind auch Ms) von Soll-Arbeitszeit abziehen
                            // Dann calcDauer

                            // Bisher nicht berücksichtigt: Mehrere Arbeitszeit-Blöcke beim selben AG am selben Tag

                            const t = soll.startzeit.split(":");
                            let startSoll = new Date(2022,1,1, t[0], t[1], t[2]);
                            const y = soll.endzeit.split(":");
                            let endSoll = new Date(2022,1,1, y[0], y[1], y[2]);
                            let sollMs = endSoll.getTime() - startSoll.getTime();

                            //window.console.log('sollMs', sollMs);

                            let gearbeitet = arbeitszeit - sollMs;
                            //window.console.log('gearbeitet', gearbeitet);
                            day.plusminus = this.store.calcDauer(gearbeitet, true);
                        }
                        else {
                            if ( arbeitszeit > 0 ){
                                day.plusminus = this.store.calcDauer(arbeitszeit, true);
                            }
                            // Schmeiß Tage ohne Soll-Arbeitszeit UND ohne Stempel aus Zeiten-Array
                            else {
                                // Hier noch Einträge aus Array schmeißen. Komplizierter Scheiß...
                            }

                        }
                    }

                }

            }

        },
        getCalendar (Monat, Jahr) {

            // zeiten-Array erst mal nullen, um eine neue Tabelle zubekommen, falls schon eine vorhanden war.
            this.zeiten = [];

            // die meisten Monate haben 31 Tage...
            let calDays = 31;

            // ...April (3), Juni (5), September (8) und November (10) haben nur 30 Tage...
            if (Monat == 3 || Monat == 5 || Monat == 8 || Monat == 10) --calDays;

            // ...und der Februar nur 28 Tage...
            if (Monat == 1) {
                calDays = calDays-3;
                // ...außer in Schaltjahren
                if (Jahr %   4 == 0) calDays++;
                if (Jahr % 100 == 0) calDays--;
                if (Jahr % 400 == 0) calDays++;
            }

            this.MonatChosen.anzahlTage = calDays;

            for (let i = 0; i < calDays; i++) {
                let Tag = i+1;
                let date = new Date(Jahr, Monat, Tag);
                //let wochentag = this.store.wochentage[date.getDay()]

                let dieserTag = {
                    datum: Tag+'.'+(Monat+1)+'.'+Jahr,
                    daystamp: date.getDate(),
                    wochentag: this.store.wochentage[date.getDay()],
                };

                this.zeiten.push(dieserTag);
            }
            //window.console.log( 'Kalender:', this.zeiten );
        }
    },
}
</script>

<style>

#zeiten .el-select, #zeiten .el-button {
    width: 100%;
}

</style>
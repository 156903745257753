<template>
    <el-row :gutter="40" v-if="store.authenticated" id="userData">
        <el-col :span="24">
            <h1>Kontaktdaten</h1>
        </el-col>


        <el-col :span="24" id="kontaktdaten">
            <el-form :model="userData" >
                <el-form-item>
                    <el-input v-model="userData.first_name">
                        <template #prepend><label>Vorname</label></template>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="userData.last_name">
                        <template #prepend><label>Nachname</label></template>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="userData.email">
                        <template #prepend><label>E-Mail</label></template>
                    </el-input>
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :span="24" id="passwoerter">
            <h3>Passwort ändern</h3>
            <el-form :model="userData">
                <el-form-item>
                    <el-input v-model="userData.oldPassword">
                        <template #prepend><label>Altes Passwort</label></template>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="userData.newPassword1">
                        <template #prepend><label>Neues Passwort</label></template>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="userData.newPassword2">
                        <template #prepend><label>Passwort wiederholen</label></template>
                    </el-input>
                </el-form-item>
                <el-button type="primary" class="form_button" @click="updateUser()">
                   Speichern
                </el-button>
            </el-form>
        </el-col>


    </el-row>

</template>

<script>
import {store} from "@/store";
import { ElNotification } from 'element-plus'

export default {
    name: 'UserData',
    data() {
        return {
            store,
            userData: {
                first_name: null,
                last_name: null,
                email: null,
                password: null,
                oldPassword: null,
                newPassword1: null,
                newPassword2: null,
            },
        }
    },
    async mounted() {
        if ( this.store.authenticated ) {
            window.console.log('UserData mounted')
            await this.getUserData();
            window.console.log('UserData:', this.userData);
        }

    },
    methods: {
        async getUserData() {
            this.userData = await this.store.server.users.me.read();
        },
        async updateUser() {
            window.console.log('Beginn updateUserAction');
            if ( this.userData ) {

                if ( this.userData.oldPassword || this.userData.newPassword1 || this.userData.newPassword2  ) {
                    if ( this.userData.newPassword1 && this.userData.newPassword2 ) {
                        if ( this.userData.newPassword1 == this.userData.newPassword2 ) {
                            if ( this.userData.password /*== this.userData.oldPassword*/ ) {

                                // Speicher neues Passwort.
                                this.userData.password = this.userData.newPassword1;

                                await this.store.server.users.me
                                    .update({
                                        password: this.userData.password,
                                    })
                            }
                            else {
                                ElNotification({
                                    title: 'Altes Passwort falsch',
                                    message: 'Dein altes Passwort ist nicht korrekt.',
                                    type: 'error',
                                })
                            }
                        }
                        else {
                            ElNotification({
                                title: 'Keine Übereinstimmung',
                                message: 'Die neuen Passwörter stimmen nicht überein.',
                                type: 'error',
                            })
                        }
                    }
                    else if ( this.userData.newPassword1 || this.userData.newPassword2 ) {
                        // Error-Alert "Bitte beide Felder ausfüllen"
                        ElNotification({
                            title: 'Keine Übereinstimmung',
                            message: 'Bitte alle Passwortfelder ausfüllen.',
                            type: 'error',
                        })
                    }
                    else {
                        // Kein neues Passwort.
                        ElNotification({
                            title: 'Error',
                            message: 'Es ist ein unvorhergesehener Fehler aufgetreten. Bitte versuche es erneut.',
                            type: 'error',
                        })
                    }
                }
                else {
                    await this.store.server.users.me
                        .update({
                            first_name: this.userData.first_name,
                            last_name: this.userData.last_name,
                            email: this.userData.email,
                        })
                        .then(() => {
                            window.console.log('updateUser erfolgreich')
                            // Alert Successfull
                            ElNotification({
                                title: 'Speichern erfolgreich',
                                message: 'Deine Kontaktdaten wurden erfolgreich aktualisiert.',
                                type: 'success',
                            })
                        })
                        .catch(() => {
                            // Alert Something went wrong
                            ElNotification({
                                title: 'Error',
                                message: 'Es ist ein unvorhergesehener Fehler aufgetreten. Bitte versuche es erneut.',
                                type: 'error',
                            })
                        });
                }
            }

        }
    }
}
</script>

<style>

#kontaktdaten .el-input-group__prepend {
    width: 10%;
    text-align: left;
    justify-content: left;
}

#passwoerter {
    margin-top: 1rem;
}

#passwoerter .el-input-group__prepend {
    width: 18%;
    text-align: left;
    justify-content: left;
}

</style>
// eslint-disable-next-line
/* eslint-disable */

//import Vue from 'vue'
import { createApp } from 'vue'

//import { h } from 'vue'
import { createWebHistory, createRouter } from "vue-router";
import ElementPlus from 'element-plus'
import de from 'element-plus/dist/locale/de.mjs'
import 'dayjs/locale/de'
//import { ElNotification } from 'element-plus'
import 'element-plus/dist/index.css'

import 'vuetify/styles'
import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify/dist/vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labsComponents from 'vuetify/labs/components'
// Wird für den Vuetify DatePicker ausm Labs gebraucht
import DayJsAdapter from '@date-io/dayjs'

import { mdi } from 'vuetify/iconsets/mdi'
import { fa } from 'vuetify/iconsets/fa'

import App from './App.vue'

import Stempeluhr from './components/StempelUhr.vue'
import Team from './components/TeamHandler.vue'
import UserData from './components/UserData.vue'
import UserZeiten from './components/UserZeiten.vue'

const routes = [
    { path: '/', name: 'App', component: App },
    { path: '/stempeluhr', name: 'Stempeluhr', component: Stempeluhr, props: true },
    { path: '/team', name: 'Team', component: Team, },
    { path: '/user', name: 'User', component: UserData },
    { path: '/userZeiten', name: 'UserZeiten', component: UserZeiten },
]

const dayjs = require('dayjs');
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

const router = createRouter({
    history: createWebHistory(),
    routes,
});

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faArrowLeft,
    faHouseChimney,
    faFileInvoice,
    faAddressCard,
    faChartSimple,
    faUser,
    faClock,
    faRightFromBracket,
    faPeopleGroup,
    faUserGroup,
    faTrashCan,
    faFloppyDisk,
    faXmark

} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faArrowLeft)
library.add(faHouseChimney)
library.add(faFileInvoice)
library.add(faAddressCard)
library.add(faChartSimple)
library.add(faUser)
library.add(faClock)
library.add(faRightFromBracket)
library.add(faPeopleGroup)
library.add(faUserGroup)
library.add(faTrashCan)
library.add(faFloppyDisk)
library.add(faXmark)

const vuetify = createVuetify({
    directives,
    icons: {
        defaultSet: 'mdi',

        sets: {
            fa,
            mdi,
        }
    },
    components: {
        ...components,
        ...labsComponents,
    },
    date: {
        adapter: DayJsAdapter,
    }
})

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)
app.use(vuetify)
app.use(dayjs)
app.use(ElementPlus, {
    locale: de,
})
app.mount('#app')
<template>
    <div v-if="store.authenticated && !viewMode" id="team">
        <v-row>
            <v-col cols="6">
                <h1>Team-Verwaltung</h1>
            </v-col>
            <v-col cols="6">
                <v-select
                    v-model="teamChosen"
                    :items="myTeams"
                    item-title="name"
                    item-value="id"
                    label="Bitte wähle ein Team aus."
                    density="compact"
                    persistent-hint
                    return-object
                    single-line
                    v-if="this.myTeams"
                ></v-select>
                <!--
                <el-select v-model="teamChosen" size="large" style="margin-top: 1.2rem; width: 100%">
                    <el-option
                        v-for="team in myTeams"
                        :key="team.id"
                        :label="team.Name"
                        :value="team.id"
                    />
                </el-select>
                -->
            </v-col>
        </v-row>
        <v-table v-if="this.members">
            <thead>
            <tr>
                <th></th>
                <th class="text-left">
                    Vorname
                </th>
                <th class="text-left">
                    Nachname
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="member in members"
                :key="member.id"
            >
                <td style="width: 10%;">
                    <img v-if="member.avatar" :src="'/assets/'+member.avatar" style="width: 100%; overflow: hidden;" />
                    <img src="@/assets/placeholderAvatar.png" style="width: 100%; overflow: hidden;" v-else />
                </td>
                <td>{{ member.first_name }}</td>
                <td>{{ member.last_name }}</td>
                <td style="text-align: right;">

                    <v-btn
                        variant="tonal"
                        icon="mdi-umbrella-beach"
                        size="x-small"
                        class="ma-2"
                        v-on:click="showDialog('holidays'); memberChosen = member;"
                    ></v-btn><v-btn
                        variant="tonal"
                        icon="fa:fas fa-clock"
                        size="x-small"
                        class="ma-2"
                        v-on:click="viewMode = 'timesheet'; memberChosen = member; getAuswertung(member.teamUserID)"
                    ></v-btn>
                    <v-btn
                        variant="tonal"
                        class="ma-2"
                        v-on:click="viewMode = 'edit'; memberChosen = member; getArbeitszeit(member.teamUserID)"
                    >
                        Bearbeiten
                    </v-btn>
                </td>
            </tr>
            </tbody>
        </v-table>

        <hr />

        <!-- Mitarbeiter hinzufügen -->
        <div v-if="teamChosen">
            <v-row >
                <v-col cols="12" >
                    <h2>Mitarbeiter hinzufügen</h2>
                </v-col>
            </v-row>
            <v-form v-model="inviteValid">
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                            v-model="inviteFirstname"
                            :rules="nameRules"
                            label="Vorname"
                            density="compact"
                            required
                        ></v-text-field>
                        <!--
                        <el-form-item>
                            <el-input v-model="newTeamMember">
                                <template #prepend><label>Mail-Adresse</label></template>
                            </el-input>
                        </el-form-item>
                        -->
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                            v-model="inviteLastname"
                            :rules="nameRules"
                            label="Nachname"
                            density="compact"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-btn
                            variant="tonal"
                            icon="fa:fas fa-plus "
                            :disabled="!inviteValid"
                            v-on:click="inviteMember()"
                            style="text-align: right;"></v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </div>

    </div>
    <div v-if="store.authenticated && viewMode=='edit'" id="team">
        <v-row>
            <v-col cols="9">
                <h1>{{ memberChosen.first_name }} {{ memberChosen.last_name }} bearbeiten</h1>
            </v-col>
            <v-col cols="3" style="display: flex; align-items: center; ">
                <v-btn variant="tonal" style="float: right;" prepend-icon="fa:fas fa-xmark" v-on:click="memberChosen = null; viewMode = null">
                    Schließen
                </v-btn>
            </v-col>
            <v-col cols="12">
                <h2>Aktuell gültige Arbeitszeiten</h2>
            </v-col>
        </v-row>

        <v-table>
            <thead>
                <tr>
                    <th class="text-left">
                        Wochentag
                    </th>
                    <th class="text-left">
                        Dauer
                    </th>
                    <th class="text-left">
                        Startzeit
                    </th>
                    <th class="text-left">
                        Endzeit
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(zeit, key) in memberArbeitszeit"
                    :key="zeit.id"
                >
                    <td style="width: 10%;">
                        <span>{{ zeit.wochentag }}</span>
                    </td>
                    <td style="width: 10%;">
                        <v-text-field
                            v-model="zeit.dauer"
                            label=""
                            required
                            density="comfortable"
                            variant="underlined"
                            :disabled="zeit.startzeit || zeit.endzeit"
                            v-if="zeit.edit == true"
                        ></v-text-field>
                        <span v-else>{{ zeit.dauer }} Stunden</span>
                    </td>
                    <td style="width: 10%;">
                        <span v-if="!zeit.startzeit && !zeit.edit">N/A</span>
                        <el-time-select
                            v-model="zeit.startzeit"
                            start="00:00"
                            step="00:15"
                            end="23:45"
                            format="HH:mm:ss"
                            v-if="zeit.edit == true"
                            @change="calcDauer(zeit.startzeit, zeit.endzeit, key)"
                        />
                    </td>
                    <td style="width: 10%;">
                        <span v-if="!zeit.endzeit && !zeit.edit">N/A</span>
                        <el-time-select
                            v-model="zeit.endzeit"
                            start="00:00"
                            step="00:15"
                            end="23:45"
                            format="HH:mm:ss"
                            v-if="zeit.edit == true"
                            @change="calcDauer(zeit.startzeit, zeit.endzeit, key)"
                        />
                        <span v-else>{{ zeit.endzeit }}</span>
                    </td>
                    <td style="width: 5%;">
                        <v-btn icon="fa:fas fa-pencil" size="small" variant="tonal" @click="zeit.edit = true;"></v-btn>
                    </td>
                </tr>
            </tbody>
        </v-table>
        <!--
        <v-row v-for="zeit in memberArbeitszeit" :key="zeit.id" id="arbeitszeit">

            <v-col cols="2">
                <span>{{ zeit.wochentag }}</span>
            </v-col>
            <v-col cols="4">
                <el-time-select
                    v-model="zeit.startzeit"
                    start="00:00"
                    step="00:15"
                    end="23:45"
                    format="HH:mm:ss"
                    v-on:change="zeit.updated = true"
                />
            </v-col>
            <v-col cols="4">
                <el-time-select
                    v-model="zeit.endzeit"
                    start="00:00"
                    step="00:15"
                    end="23:45"
                    format="HH:mm:ss"
                    v-on:change="zeit.updated = true"
                />
            </v-col>
            <v-col cols="2">
                <el-button type="danger" style="float: right;" class="solo" v-on:click="deleteArbeitszeit(zeit)">
                    <font-awesome-icon icon="fa-solid fa-trash-can" />
                </el-button>
            </v-col>
        </v-row> -->

        <!--
        <v-row id="arbeitszeit" v-if="addZeit">
            <v-col cols="12">
                <hr style="margin: 1rem 0;" />
            </v-col>
            <v-col cols="4">
                <el-select v-model="newZeit.wochentag" placeholder="Wochentag">
                    <el-option
                        v-for="tag in this.store.wochentage"
                        :key="tag"
                        :label="tag"
                        :value="tag"
                    />
                </el-select>
            </v-col>
            <v-col cols="4">
                <el-time-select
                    v-model="newZeit.startzeit"
                    placeholder="Startzeit"
                    start="00:00"
                    step="00:15"
                    end="23:45"
                    format="HH:mm:ss"
                />
            </v-col>
            <v-col cols="4">
                <el-time-select
                    v-model="newZeit.endzeit"
                    placeholder="Endzeit"
                    start="00:00"
                    step="00:15"
                    end="23:45"
                    format="HH:mm:ss"
                />
            </v-col>
        </v-row>

       -->
        <v-row style="margin-top: 0.5rem;">
            <!--
            <v-col cols="4">
                <el-button type="primary" style="float: left;" v-on:click="addZeit = true;">Arbeitszeit hinzufügen</el-button>
            </v-col>
            -->
            <v-col cols="5" style="display: flex; justify-content: flex-start;">
                <h3 class="mt-3">Änderungen gültig ab:</h3>
            </v-col>
            <v-col cols="7" style="display: flex; justify-content: flex-end;">
                <el-date-picker
                    v-model="startDate"
                    type="date"
                    placeholder="Änderung gültig ab:"
                    format="DD.MM.YYYY"
                    size="large"
                />
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="Wähle, ab wann die Änderungen in Kraft treten."
                    placement="top"
                    v-if="!startDate"
                >
                    <span>
                        <v-btn
                            :disabled="!startDate"
                            variant="tonal"
                            prepend-icon="fa:fas fa-floppy-disk"
                            style="margin-left: 20px;"
                        >
                        Speichern
                    </v-btn></span>
                </el-tooltip>
                <v-hover v-slot="{ isHovering, props }" v-else>
                    <v-btn
                        variant="tonal"
                        prepend-icon="fa:fas fa-floppy-disk"
                        :color="isHovering ? store.colors.darkblue : store.colors.primaryblue"
                        style="margin-left: 20px;"
                        v-on:click="saveArbeitszeit()"
                        v-bind="props"
                    >
                        Speichern
                    </v-btn>
                </v-hover>
            </v-col>

            <v-col cols="12">
                <hr />

                <h2>Zukünftige Arbeitszeiten</h2>

                <v-data-table
                    :headers="futureWorktimeHeaders"
                    :items="futureTimeSets"
                    item-value="id"
                    class="elevation-1"
                >
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            size="small"
                            class="me-2"
                            @click="editWorktimeDialog(item.raw)"
                        >
                            mdi-eye
                        </v-icon>
                        <v-icon
                            size="small"
                            @click="deleteFutureWorktime(item.raw)"
                        >
                            mdi-delete
                        </v-icon>
                    </template>

                    <!-- v-slot:bottom um das Footer-Geraffel auszublenden -->
                    <template v-slot:bottom>
                    </template>

                    <template v-slot:no-data>
                        <span>Keine Daten verfügbar</span>
                    </template>

                </v-data-table>
            </v-col>

            <v-col cols="12">
                <hr />

                <h2>Weitere Aktionen</h2>
                <v-btn variant="tonal" color="red" style=" margin-top: 1rem;">{{ memberChosen.first_name }} {{ memberChosen.last_name }} deaktivieren</v-btn>
            </v-col>
        </v-row>


    </div>
    <div v-if="store.authenticated && viewMode=='timesheet'" id="team">
        <v-row>
            <v-col cols="9">
                <h1>Arbeitszeit-Auswertung</h1>
                <span>Auswertung zu {{ memberChosen.first_name }} {{ memberChosen.last_name }}</span>
            </v-col>
            <v-col cols="3" style="display: flex; align-items: center; ">
                <v-btn variant="tonal" style="float: right;" prepend-icon="fa:fas fa-xmark" v-on:click="memberChosen = null; viewMode = null">
                    Schließen
                </v-btn>
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center">
                <h3> {{ this.store.getDate(this.startZeitraum) }} – {{  this.store.getDate(this.endZeitraum)  }}</h3>
            </v-col>
            <v-col cols="12" md="6">
                <v-btn variant="tonal" style="float: right;" prepend-icon="mdi-swap-horizontal" @click="showDialog('dateinputs')">
                    Zeitraum wechseln
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :headers="AuswertungHeaders"
            :items="auswertung"
            item-value="id"
        >
            <template v-slot:[`item.symbols`]="{ item }">
                <v-icon
                    v-if="item.raw.absence == 'krank'"
                    size="small"
                    class="me-2"
                    color="red"
                >
                    fa:fas fa-house-medical
                </v-icon>
                <v-icon
                    v-if="item.raw.absence == 'urlaub'"
                    size="small"
                    class="me-2"
                    color="blue"
                >
                    mdi-umbrella-beach
                </v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    size="small"
                    class="me-2"
                    v-if="item.raw"
                    @click="showAuswertungStamps(item.raw)"
                >
                    mdi-eye
                </v-icon>
            </template>


            <template v-slot:bottom>
            </template>

            <template v-slot:no-data>
                <span>Keine Daten verfügbar</span>
            </template>
        </v-data-table>
    </div>

    <v-dialog
        v-model="dialog.showWorktime"
        max-width="800px"
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">Arbeitszeiten</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="2" md="2">
                            <span> Wochentag</span>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                            <span> Dauer</span>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                            <span> Startzeit</span>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                            <span> Endzeit</span>
                        </v-col>
                    </v-row>
                    <v-row v-for="day in editZeit.timerules" :key="day.id">
                        <hr />
                        <v-col
                            cols="12"
                            sm="2"
                            md="2"
                        >
                            <span> {{ day.wochentag }}</span>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="3"
                            md="3"
                        >
                            <span> {{ day.dauer }} Stunden</span>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="3"
                            md="3"
                        >
                            <span v-if="!day.startzeit">N/A</span>
                            <span> {{ day.startzeit }}</span>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="3"
                            md="3"
                        >
                            <span v-if="!day.endzeit">N/A</span>
                            <span> {{ day.endzeit }}</span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :color=this.store.colors.primaryblue
                    variant="text"
                    @click="dialog.showWorktime = false"
                >
                    Schließen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog.showDatepicker"
        max-width="400px"
    >
        <v-date-picker v-model="test" range></v-date-picker>

    </v-dialog>

    <v-dialog
        v-model="dialog.showDateInputs"
        max-width="400px"
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">Zeitraum wählen</span>
            </v-card-title>

            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="4">
                            <v-list-subheader>Von:</v-list-subheader>
                        </v-col>

                        <v-col cols="8">
                            <v-text-field v-model="startZeitraum" label="XXXX-XX-XX"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-list-subheader>Bis:</v-list-subheader>
                        </v-col>

                        <v-col cols="8">
                            <v-text-field v-model="endZeitraum" label="XXXX-XX-XX"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-btn block class="mt-2" @click="getAuswertung(memberChosen); this.dialog.showDateInputs = false;">Zeitraum wählen</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog.showAuswertungDetails"
        max-width="800px"
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">Auswertung zum (Tag X)</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6">
                            <span> Zeitpunkt</span>
                        </v-col>
                        <v-col cols="12" md="6">
                            <span> Typ</span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :color=this.store.colors.primaryblue
                    variant="text"
                    @click="dialog.showAuswertungDetails = false"
                >
                    Schließen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog.showHolidayInput"
        max-width="400px"
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">Abwesenheiten buchen</span>
            </v-card-title>

            <v-card-text>
                <v-container fluid>

                    <h3>Abwesenheitstyp</h3>
                    <v-select
                        v-model="abwesenheit"
                        label="Abwesenheitstyp"
                        :items="[ { label: 'Krank', value: 'krank' },{ label: 'Urlaub', value: 'urlaub' } ]"
                        item-title="label"
                        item-value="value"
                    ></v-select>

                    <h3>Abwesend von:</h3>
                    <v-text-field v-model="startAbwesenheit" label="YYYY-MM-DD"></v-text-field>
                    <h3>Abwesend bis:</h3>
                    <v-text-field v-model="endAbwesenheit" label="YYYY-MM-DD"></v-text-field>

                    <v-btn block class="mt-2" @click="saveHoliday(abwesenheit); this.dialog.showHolidayInput = false;">Bestätigen</v-btn>

                </v-container>
            </v-card-text>
        </v-card>


    </v-dialog>

</template>



<script>
import {store} from "@/store";
const dayjs = require('dayjs');
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

export default {
    name: "TeamHandler",
    data() {
        return {
            store,
            // futureWorktime Table
            //expanded: [],

            // Headers
            futureWorktimeHeaders: [
                {
                title: 'Gültig ab',
                sortable: false,
                key: 'gueltig_ab',
                },
                {
                    title: 'Gültig bis',
                    sortable: false,
                    key: 'gueltig_bis',
                },
                {
                    title: 'Angelegt am',
                    sortable: false,
                    key: 'date_created',
                },
                //{ title: '', key: 'data-table-expand' },
                { title: 'Actions', key: 'actions', sortable: false },
            ],
            AuswertungHeaders: [
                {
                    title: '',
                    sortable: false,
                    key: "symbols"
                },
                {
                    title: 'Datum',
                    sortable: false,
                    key: "date"
                },
                {
                    title: 'Arbeitszeit',
                    sortable: false,
                    key: "arbeitszeit"
                },
                {
                    title: 'Pause',
                    sortable: false,
                    key: "break"
                },
                /*
                {
                    title: 'Soll',
                    sortable: false,
                    key: "soll"
                },
                 */
                {
                    title: 'Differenz',
                    sortable: false,
                    key: "diff"
                },
                { title: 'Actions', key: 'actions', sortable: false },
            ],

            // ViewMode null, edit oder timesheet
            viewMode: null,

            // False -> Dialog-Moda ausgeblendet, True -> Dialog-Modal im Popup
            dialog: {
                showWorktime: false,
                showDatepicker: false,
                showDateInputs: false,
                showAuswertungDetails: false,
                showHolidayInput: false,
            },

            // Alle dem eingeloggten User zur Verfügung stehenden Teams
            myTeams: null,

            // Wenn User ein Team gewählt hat
            teamChosen: null,

            // Gewählter teamUser, für Auswertung etc., der nicht der eigene ist.
            teamUserID: null,

            newTeamMember: null,

            // Wenn Member in ViewMode = "edit" geöffnet wird.
            memberChosen: null,
            addZeit: false,
            newZeit: {
                wochentag: null,
                startzeit: null,
                endzeit: null,
            },
            // Arbeitszeiten-Sets bearbeiten
            editZeit: null,
            editIndex: null,

            // Startzeitpunkt für Arbeitszeit-Änderung (neues Set)
            startDate: null,
            members: [],
            memberArbeitszeit: [],

            // Aktuelle Arbeitszeit zum Member
            aktivesTimeSet: null,

            // Zukünftige zum Member gespeicherte Sets
            futureTimeSets: [],

            // Timesheet / Arbeitszeit-Auswertung
            startZeitraum: null,
            endZeitraum: null,
            auswertung: [],
            stamps: null,

            // Dialog für Abwesenheitszeiten
            abwesenheit: 'krank',
            startAbwesenheit: null,
            endAbwesenheit: null,

            // Neuen Team-Member hinzufügen
            inviteValid: false,
            inviteFirstname: null,
            inviteLastname: null,
            nameRules: [
                value => {
                    if (value) return true

                    return 'Der Name ist ein Pflichtfeld.'
                },
                value => {
                    if (value?.length >= 1) return true

                    return 'Gib einen vollständigen Namen ein.'
                },
            ],
            test: [],
        }
    },
    async created() {
        if ( this.store.authenticated ) {
            this.myTeams = await this.store.getTeams();
            this.teamChosen = this.store.team;

            this.editMode = false;

            let jetzt = new Date;
            let year = jetzt.getFullYear()
            let month = this.store.doNull(jetzt.getMonth()+1);

            this.startZeitraum = year +'-'+month+'-01';
            this.endZeitraum = year +'-'+month+'-31';

            this.startAbwesenheit = this.store.getDateStamp();
            this.endAbwesenheit = this.store.getDateStamp();

            // Macht jetzt der Watcher!
            // await this.getMembers(this.teamChosen);
        }
    },
    methods: {
        async getMembers(teamChosen) {
            if ( teamChosen ) {

                // Members nullen
                this.members = [];

                // Hol mir die Member-Daten des aktuell gewählten Teams
                const team = await this.store.server.items('team_directus_users').readByQuery({
                    filter: {
                        team_id: {
                            _eq: teamChosen,
                        },
                    },
                    sort: ['id'],
                });
                var members = team.data;
                //this.teamUserID = team.data.id;
                window.console.log('Members', members)

                // Hol mir das User-Objekt
                for (const member of members ) {
                    const memberlein = await this.store.server.items('directus_users').readOne(member.directus_users_id);
                    memberlein['teamUserID'] = member.id;
                    this.members.push(memberlein)
                    //window.console.log('PUSH!!', memberlein)
                }
                window.console.log('this.members', this.members)
            }
        },

        // Funktion, die die Arbeitszeit-Regeln holt (nicht die Auswertung der geleisteten Arbeitszeit)
        async getArbeitszeit(memberChosenID) {
            if ( memberChosenID ) {

                // Hol mir die Member-Daten des aktuell gewählten Teams
                const teamUser = await this.store.server.items('team_directus_users').readByQuery({
                    filter: {
                        id: {
                            _eq: memberChosenID,
                        },
                    },
                    limit: 1
                });
                this.teamUserID = teamUser.data[0].id;
                window.console.log('teamUserID', teamUser, this.teamUserID)

                // Hol mir die Time-Sets zum gewählten User
                const timerulesSet = await this.store.server.items('timerules').readByQuery({
                    filter: {
                        team_user: {
                            _eq: this.teamUserID,
                        }
                    },
                    sort: ['id'],
                });
                window.console.log('Timerules', timerulesSet.data )

                this.futureTimeSets = [];

                for ( let sets of timerulesSet.data ){

                    // Hol mir die Time-Sets zum gewählten User
                    const timerules = await this.store.server.items('timerules_days').readByQuery({
                        filter: {
                            timerule_set: {
                                _eq: sets.id
                            }
                        },
                        sort: ['id'],
                    });

                    let heute = new Date();
                    heute = this.store.getDateStamp(heute);

                    if ( sets.status == 'active' && sets.gueltig_ab <= heute ){ // && sets.gueltig_ab <
                        this.aktivesTimeSet = sets;
                        this.memberArbeitszeit = timerules.data;
                        window.console.log('TimerulesDays', timerules.data)
                    }
                    if ( sets.status == 'active' && sets.gueltig_ab > heute ){
                        sets.gueltig_ab = this.store.getDate(sets.gueltig_ab);
                        sets.date_created = this.store.getDate(sets.date_created)

                        // Sonst setzt er Datum von 1970
                        if ( sets.gueltig_bis ){
                            sets.gueltig_bis = this.store.getDate(sets.gueltig_bis);
                        }

                        sets.timerules = timerules.data;

                        this.futureTimeSets.push(sets);
                        window.console.log('Gilt noch nicht')
                    }
                    if ( sets.status == 'inactive' ) {
                        window.console.log('Inaktiv')
                    }
                }

            }
        },
        // Speichert neue Arbeitszeit-Regelung
        async saveArbeitszeit() {

            /*
            if ( this.memberArbeitszeit.length > 0 ){
                window.console.log('memberAZeit', this.memberArbeitszeit)

                for ( const zeit of this.memberArbeitszeit ) {

                    if ( zeit.updated ) {
                        await this.store.server.items('Arbeitszeit').updateOne(zeit.id, {
                            startzeit: zeit.startzeit,
                            endzeit: zeit.endzeit,
                        });
                        window.console.log('saveZeit', zeit)
                    }
                }

                if ( this.newZeit ) {
                    await this.addArbeitszeit( this.newZeit, this.memberChosen, this.teamChosen );
                }

                this.startDate = null;
            }
            */

            let startdate = new Date(this.startDate.getTime());
            // Aus welchen Gründen auch immer muss ich das StartDate neu formatieren, sonst nimmt er einen Tag weniger?!?
            // Also beispielsweise startDate soll der 7.7. sein, dann speichert er den 6.7. So gehts jetzt.
            var startdateKannMichMal = startdate.getFullYear() + "-" + this.store.doNull(startdate.getMonth()+1) + "-" + this.store.doNull(startdate.getDate());

            let newSet = await this.store.server.items('timerules').createOne({
                team_user: this.teamUserID,
                gueltig_ab: startdateKannMichMal,
            });

            if ( newSet ){

                for ( let zeit of this.memberArbeitszeit ){
                    //window.console.log( newSet );
                    //window.console.log( zeit );

                    await this.store.server.items('timerules_days').createOne({
                        wochentag: zeit.wochentag,
                        dauer: zeit.dauer,
                        timerule_set: newSet.id,
                        von: zeit.startzeit,
                        bis: zeit.endzeit
                    });

                    zeit.edit = false;
                }
            }

            let date = new Date(this.startDate.getTime());
            //window.console.log('date', date)

            date.setDate(date.getDate() -1 );
            //window.console.log('endDate', date)

            var enddate = date.getFullYear() + "-" + this.store.doNull(date.getMonth()+1) + "-" + this.store.doNull(date.getDate());
            //window.console.log(enddate)

            // AHHHHH! Es soll natürlich nicht das aktive TimeSet updaten, sondern das letzte, was noch kein "gueltig_bis" besitzt.
            // NOCH ÄNDERN!
            await this.store.server.items('timerules').updateOne(this.aktivesTimeSet.id, {
                gueltig_bis: enddate
            });

            //window.location.reload();
            //this.viewMode = 'edit';
            this.startDate = null;
            this.getArbeitszeit();

        },
        // geplantes Arbeitszeit-Modell (das noch nicht gestartet ist) ändern
        editWorktimeDialog (item) {
            this.editZeit = Object.assign({}, item)
            this.dialog.showWorktime = true
        },
        // geplantes Arbeitszeit-Modell löschen
        async deleteFutureWorktime(zeit) {
            if ( zeit ){
                window.console.log('deleteZeit', zeit)
                await this.store.server.items('timerules').deleteOne(zeit.id);
                await this.getArbeitszeit(this.memberChosen.id);
            }
        },
        showDialog(type){
            switch (type) {
                case 'datepicker':
                    // Zukünftiges Dialog-Fenster um den Zeitraum für die Auswertung zu ändern
                    this.dialog.showDatepicker = true;
                    break;
                case 'dateinputs':
                    // Dialog-Fenster um den Zeitraum für die Auswertung zu ändern
                    this.dialog.showDateInputs = true;
                    break;
                case 'holidays':
                    // Dialog-Fenster um Urlaub/Krank auf einen Mitarbeiter zu buchen.
                    this.dialog.showHolidayInput = true;
                    break;
                default:
                    this.dialog.showDatepicker = true;
            }
        },
        calcDauer(startzeit, endzeit, key) {
            if ( startzeit && endzeit ){
                const start = startzeit.split(":");
                const end = endzeit.split(":");

                let startrate = parseFloat(start[0]+'.'+(start[1]/60*100))
                let endrate = parseFloat(end[0]+'.'+(end[1]/60*100))

                let dauer = endrate - startrate;

                if ( dauer < 0 ){
                    dauer = 0;
                }

                //window.console.log('calcDauer', dauer, startrate, endrate )
                this.memberArbeitszeit[key].dauer = dauer;
            }

        },
        async addArbeitszeit(zeit, member, team) {
            if ( zeit ){
                window.console.log('addZeit', zeit)
                await this.store.server.items('Arbeitszeit').createOne({
                    wochentag: zeit.wochentag,
                    startzeit: zeit.startzeit,
                    endzeit: zeit.endzeit,
                    userID: member.id,
                    teamID: team,
                    startdate: this.startDate,
                    enddate: null,
                });
                this.addZeit = false;
                await this.getArbeitszeit(this.memberChosen.id);
            }
        },
        async getAuswertung() {

            this.auswertung = [];

            const dayTime = await this.store.server.items('day_times').readByQuery({
                filter: {
                    team_user: {
                        _eq: this.memberChosen.teamUserID,
                    },
                    date: {
                        _between: [this.startZeitraum, this.endZeitraum]
                    }
                },
                sort: ['id'],
            });

            // Hole alle Timerule-Sets, die im gewählten Zeitraum gültig sind oder werden
            const timerules_sets = await this.store.server.items('timerules').readByQuery({
                filter: {
                    team_user: {
                        _eq: this.memberChosen.teamUserID,
                    },
                    gueltig_ab: {
                        _lte: this.endZeitraum,
                    }
                },
                sort: ['id'],
            });

            window.console.log('AlleTimesets', timerules_sets.data)

            for ( let day of dayTime.data ){

                let auswertung = {
                    date: null,
                    arbeitszeit: null,
                    break: null,
                    soll: null,
                    diff: null
                };

                //let d = new Date(day.date);
                let d = this.store.getDay(day.date);

                let w = this.store.wochentage[d].toLowerCase();

                const timerule = await this.store.server.items('timerules_days').readByQuery({
                    filter: {
                        _and: [
                            {
                                _or: [
                                    {
                                        timerule_set: {
                                            gueltig_bis: {
                                                _null: true,
                                            },
                                        }
                                    },

                                    {
                                        timerule_set: {
                                            gueltig_bis: {
                                                _gte: this.store.getToday(),
                                            },
                                        }
                                    }
                                ],
                            },
                            {
                                wochentag: {
                                    _eq: w,
                                }
                            },
                            {
                                timerule_set: {
                                    team_user: {
                                        _eq: this.memberChosen.teamUserID,
                                    }
                                },
                            },
                            {
                                timerule_set: {
                                    gueltig_ab: {
                                        _lte: '$NOW',
                                    }
                                },
                            }
                        ],
                    },
                    limit: 1,
                    sort: ['id'],
                });

                let soll = dayjs.duration(timerule.data[0].dauer, 'hours');

                window.console.log('timerulesNested', w, timerule.data);
                window.console.log('Sollzeit', timerule.data[0].dauer, soll.asMilliseconds(), this.store.calcDauer(soll.asMilliseconds()));

                auswertung.date = day.date;
                auswertung.arbeitszeit = this.store.calcDauer(day.duration);
                auswertung.break = this.store.calcDauer(day.break);
                auswertung.soll = this.store.calcDauer(soll.asMilliseconds());
                auswertung.absence = day.absence;

                auswertung.diff = this.store.calcDauer(day.duration - soll.asMilliseconds(), true);

                window.console.log('auswertungDay', auswertung);
                this.auswertung.push(auswertung);
            }

            window.console.log('Auswertung', this.auswertung)

        },
        async showAuswertungStamps(d){
            let item = Object.assign({}, d)

            let day = this.store.getDateStamp(item.date);
            window.console.log(day);

            const stamps = await this.store.server.items('stamps').readByQuery({
                filter: {
                    team_user: {
                        _eq: this.memberChosen.teamUserID,
                    },
                    time: {
                        _between: [day+" 00:00:00", day+" 23:59:59"],
                    }
                },
                sort: ['id'],
            });

            window.console.log(stamps);

            for ( let s of stamps.data ){
                let stempel = [];

                let d = new Date(s.time);
                let date = this.store.getDateStamp(d)+' '+this.store.getClock(d)
                window.console.log(d, date);

                stempel['date'] = date;
                stempel['type'] = s.type;

                window.console.log(stempel);
            }

            window.console.log(this.stamps);

            this.dialog.showAuswertungDetails = true
        },
        async saveHoliday(type) {

            // Prüfen, ob am eingetragenen Urlaubstag überhaupt Arbeitszeit ist
            // read Länge der Arbeitszeit
            // update Länge der Arbeitszeit in day_times

            window.console.log(type)

            let end = new Date(this.endAbwesenheit);

            let loop = new Date(this.startAbwesenheit);
            while ( loop <= end ){

                // -1, weil getDay mit 0 = Sunday beginnt und unser Array "wochentage" mit 0 = Montag.
                let w = this.store.wochentage[this.store.getDay(loop)].toLowerCase();
                window.console.log('Wochentag', w, this.store.getDay(loop), 'start', loop, 'end', end)

                const timerule = await this.store.server.items('timerules_days').readByQuery({
                    filter: {
                        _and: [
                            {
                                _or: [
                                    {
                                        timerule_set: {
                                            gueltig_bis: {
                                                _null: true,
                                            },
                                        }
                                    },

                                    {
                                        timerule_set: {
                                            gueltig_bis: {
                                                _gte: this.store.getToday(),
                                            },
                                        }
                                    }
                                ],
                            },
                            {
                                wochentag: {
                                    _eq: w,
                                }
                            },
                            {
                                timerule_set: {
                                    team_user: {
                                        _eq: this.memberChosen.teamUserID,
                                    }
                                },
                            },
                            {
                                timerule_set: {
                                    gueltig_ab: {
                                        _lte: '$NOW',
                                    }
                                },
                            }
                        ],
                    },
                    limit: 1,
                    sort: ['id'],
                });

                window.console.log(loop, timerule)

                // Nur wenn er gültige Arbeitszeiten für diesen Tag findet, trage die Urlaubs-/Krankheitszeit ein.
                // Eventuell Krank/Urlaub immer eintragen, aber keine Arbeitszeit gutschreiben?
                if ( timerule.data.length > 0 && timerule.data[0].dauer > 0 ){

                    let dauerMs = dayjs.duration(timerule.data[0].dauer, 'hours').asMilliseconds();

                    const day_times = await this.store.server.items('day_times').readByQuery({
                        filter: {
                            team_user: {
                                _eq: this.memberChosen.teamUserID
                            },
                            date: {
                                _eq: loop,
                            }
                        },
                        limit: 1,
                        sort: ['id'],
                    });

                    window.console.log(dauerMs, day_times.data)

                    if ( day_times.data.length > 0 ){

                        dauerMs = day_times.data[0].duration + dauerMs;

                        await this.store.server.items('day_times').updateOne(day_times.data[0].id, {
                            absence: type,
                            duration: dauerMs,
                        });
                    }
                    else {
                        await this.store.server.items('day_times').createOne({
                            date: loop,
                            team_user: this.memberChosen.teamUserID,
                            absence: type,
                            duration: dauerMs,
                        });
                    }

                    window.console.log('DayTimes', day_times)

                }



                loop.setDate( loop.getDate() +1 );
            }

            this.memberChosen = null;

        },
        async inviteMember() {
            // Alter Bums
            // await this.store.server.users.invites.send(this.newTeamMember, '9764a194-7775-4f53-a272-5d15868f8855');

            if ( this.inviteValid ){
                const newUser = await this.store.server.users.createOne({
                    first_name: this.inviteFirstname,
                    last_name: this.inviteLastname
                });

                window.console.log('newUser', newUser)

                const userTeam = await this.store.server.items('team_directus_users').createOne({
                    team_id: this.teamChosen.id,
                    directus_users_id: newUser.id,
                    roles: 3
                });

                window.console.log('userTeam', userTeam)

                let today = this.store.getToday();

                const timeruleSet = await this.store.server.items('timerules').createOne({
                    team_user: userTeam.id,
                    status: 'active',
                    gueltig_ab: today,
                });

                window.console.log('new TimeruleSet', timeruleSet)

                for ( let day of this.store.wochentage ){
                    const timerules = await this.store.server.items('timerules_days').createOne({
                        wochentag: day,
                        dauer: 0,
                        timerule_set: timeruleSet.id
                    })

                    window.console.log('new TimeruleDay', timerules)
                }


                // Das hilft leider auch nicht...
                setTimeout(() => {
                    this.getMembers(this.teamChosen.id);
                }, 1000);


            }
        },
    },
    watch: {
        teamChosen: {
            handler(chosen) {
                this.members = [];
                //window.console.log('Empty Array', this.members)
                if (chosen != null){
                    this.getMembers(this.teamChosen.id);
                }
                window.console.log('Get Members Neu', chosen, this.teamChosen)
            },
            immediate: true
        }
    }
}
</script>

<style>

.v-dialog hr {
    margin: 0.5rem 0;
}

</style>
<template>
    <el-row :gutter="40" v-if="store.authenticated" id="stempeluhr">

        <el-col :span="12">
            <div id="zeitfenster">
                <span id="zeit">{{ clock }}</span>
                <span id="datum">{{ date }}</span><br />
                <span id="state" v-if="state == 'anwesend'">Anwesend seit {{ stateTime }}</span>
                <span id="state" v-if="state == 'pause'">Pause seit {{ statePause }}</span>
                <span id="state" v-if="state == 'abwesend'">Du bist zur Zeit nicht eingestempelt.</span>
            </div>

        </el-col>
        <el-col :span="12">
            <el-row :gutter="20" v-if="state == 'abwesend'">
                <el-col :span="24" class="stempeluhrActions" >
                    <el-radio-group v-model="teamChosenID" size="large" v-if="this.store.teams.length <= 2">
                        <el-radio-button v-for="team in this.store.teams" :key="team.id" :label="team.id"> {{ team.name }}</el-radio-button>
                    </el-radio-group>
                    <el-select v-model="teamChosenID" v-if="this.store.teams.length > 2" size="large">
                        <el-option
                            v-for="team in this.store.teams"
                            :key="team.id"
                            :label="team.name"
                            :value="team.id"
                        />
                    </el-select>
                </el-col>
                <!--<el-col :span="12">
                    <el-button plain type="primary" style="width: 100%">Team 2</el-button>
                </el-col>-->
                <el-col :span="24" style="margin-top: 20px">
                    <el-switch v-model="lastProject" style="--el-switch-on-color: rgba(122,184,229); --el-switch-off-color: rgba(183,228,255); margin-right: 20px" />
                    <span>In letztes Projekt einstempeln</span>
                </el-col>
                <el-col :span="24">
                    <el-button class="big_button" size="large" type="primary" style="margin-top: 30px" @click="stempeln('arrive', this.teamChosenID)">Einstempeln</el-button>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-if="state == 'pause'">
                <el-col :span="24">
                    <el-button class="big_button" size="large" type="primary" @click="stempeln('arrive', this.store.teamID)">Pause beenden</el-button>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-if="state == 'anwesend'">
                <el-col :span="24">
                    <el-button class="big_button" size="large" type="primary" @click="stempeln('break', this.store.teamID)">Pause beginnen</el-button>
                    <el-button class="big_button" size="large" type="primary" style="margin: 30px 0 0 0;" @click="stempeln('leave', this.store.teamID)">Ausstempeln</el-button>
                </el-col>
            </el-row>
        </el-col>
    </el-row>

    <v-btn @click="this.store.test()">Test</v-btn>


</template>

<script>
import { store } from '@/store.js'

export default {
    name: 'StempelUhr',
    //authenticated: null,
    props: {
        //authenticated: null,
        //server: null,
    },
    computed: {
        clock() {
            let a = new Date();
            let hours = a.getHours();
            let minutes = a.getMinutes();
            if(hours < 10){hours = '0'+hours;}
            if(minutes < 10){minutes = '0'+minutes;}
            return hours+':'+minutes+' Uhr';
        },
        date() {
            let b = new Date();
            let day = b.getDate();
            let month = b.toLocaleString('de-DE', { month: 'long' });
            let year = b.getFullYear();
            return day+'. '+month+' '+year;
        },
    },
    data() {
        return {
            store,
            state: null,
            stateTime: null,
            statePause: null,
            lastProject: null,
            teamChosenID: null,
        }
    },
    async mounted() {
        if ( this.store.authenticated ) {
            //window.console.log('Stempeluhr mounted')
            await this.store.getTeams();

            this.teamChosenID = this.store.teams[0].id;

            await this.getState();
            //window.console.log('Hol mal State:', this.state);
        }
    },
    methods: {
        async getState() {

            let stempel = null;

            // Holt alle Stamps zum Account, nicht den TeamUser (der ist noch nicht eindeutig gesetzt)
            stempel = await this.store.server.items('stamps').readByQuery({
                filter: {
                    team_user: {
                        //_eq: this.store.teamUser.id,
                        "directus_users_id": {
                            _in: "$CURRENT_USER"
                        }
                    }
                },
                limit: 1,
                sort: ['-id'],
            });

            window.console.log('stamps', stempel)

            if ( stempel.data.length === 0 ) {
                this.state = 'abwesend';
            }
            else {
                //this.stateTime = stempel.data[0].date_created;

                switch ( stempel.data[0].type ) {
                    case "arrive":
                        this.state = 'anwesend';
                        this.store.getTeam(stempel.data[0].team_user);
                        this.stateTime= this.store.getClock(stempel.data[0].date_created);
                        break;
                    case "leave":
                        this.state = 'abwesend';
                        break;
                    case "break":
                        this.state = 'pause';
                        this.store.getTeam(stempel.data[0].team_user);
                        this.statePause = this.store.getClock(stempel.data[0].date_created);
                        break;
                    case "pauseEnd":
                        this.state = 'anwesend';
                        // Wenn PauseEnd als letztes gestempelt wurde,
                        // dann muss ich den letzten Kommen-Stempel davor holen um "eingestempelt seit" ausgeben zu können.
                        var kommen = await this.store.server.items('stamps').readByQuery({
                            filter: {
                                user_created: {
                                    _eq: '$CURRENT_USER',
                                },
                                type: {
                                    _eq: 'arrive',
                                }
                            },
                            limit: 1,
                            sort: ['-id'],
                        });
                        this.stateTime = this.store.getClock(kommen.data[0].date_created);
                        break;
                    default:
                        this.state = 'schläft';
                }
            }
            window.console.log('getState', stempel)
        },
        async stempeln(vorgang, teamChosenID) {

            let date = new Date();
            //window.console.log(date);

            // Setze jetzt das vollwertige Team-Model und das TeamUser-Model
            await this.store.getTeam(null, teamChosenID);

            // Kein Mensch weiß, wieso im Log GTM +2 ausgegeben wird, aber GTM +0 in der DB landet...
            const stemp = await this.store.server.items('stamps').createOne({
                type: vorgang,
                team_user: this.store.teamUser,
                time: date,
            });
            window.console.log('Stempel mal', stemp, vorgang, teamChosenID)

            switch ( vorgang ) {
                case "arrive":

                    // Wenn man aus der Pause kommt, dann persistiere Pausen-Länge, bevor state auf "Anwesend" geändert wird.
                    if (this.state == 'pause' ){
                        await this.store.calcDayTime(stemp, 'break');
                    }

                    this.state = 'anwesend';
                    //this.store.teamID = teamID;
                    this.stateTime = this.store.getClock(stemp.date_created);
                    break;
                case "leave":
                    this.state = 'abwesend';

                    await this.store.calcDayTime(stemp, 'leave');

                    this.store.teamID = null;
                    this.store.team = null;
                    // teamUser auch nullen?

                    break;
                case "break":
                    this.state = 'pause';
                    this.statePause = this.store.getClock(stemp.date_created);
                    await this.store.calcDayTime(stemp, 'leave');
                    break;
                // pauseEnd gibt es eigentlich nicht mehr.
                case "pauseEnd":
                    this.state = 'anwesend';

                    break;
                default:
                    this.state = 'schläft';
            }
        },
        async logout() {
            await this.store.server.auth.logout();
            this.$router.push({ name: 'App' });
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

#zeitfenster {
    background-color: #ecf5ff;
    border: 1px solid rgba(183,228,255);
    padding: 1.5rem 1rem;
    border-radius: 4px;
    color: rgba(122,184,229);
    text-align: center;
}

#stempeluhr span#zeit {
    font-size: xxx-large;
    margin-bottom: -5px;
    display: block;
}

#stempeluhr span#datum {
    font-size: x-large;
    text-transform: uppercase;
}

#stempeluhr span#state {
    display: block;
    margin-top: 15px;
}

#stempeluhr .el-select {
    width: 100%;
}

#stempeluhr .el-select .el-input__inner {
    color: rgba(122,184,229);
    text-transform: uppercase;
    font-weight: bold;
}

.stempeluhrActions .el-select, .stempeluhrActions .el-radio-group {
    width: 100%;
}

</style>
